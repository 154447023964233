<template>
    <div class="flex" :class="containerStyle">
        <div class="flex flex-col space-y-10 justify-center p-[3rem] md:p-[5rem]" :class="contentStyle">
            <h3 class="font-medium text-3xl md:text-6xl">{{ productData?.name }}</h3>

            <div class="flex flex-col space-y-3 text-lg md:text-xl" :class="contentStyle">
                <p>{{ productData?.shortDescription }}</p>
                <StarRating class="w-fit" :rating="productData?.reviewSummary.averageRating || 0"/>
                <span class="font-medium">{{ productData?.price }}</span>
            </div>

            <Button class="w-fit" :theme="props.theme" size="lg" @click="addToCart(productData?.id)"
                      :aria-label="`Add ${productData?.name} to cart`" v-if="props.showAddToCartButton">
                Add to Cart
            </Button>
        </div>
        <div class="p-[3rem] md:p-[5rem]">
            <NuxtImg class="m-auto bg-gray-300 bg-opacity-25" :src="props.img || productData?.primaryImageUrl"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useToastStore} from '~/stores/toastStore'
import Button from '~/components/ui/atom/button.vue'
import StarRating from '~/components/ui/atom/starRating.vue'
import {NuxtImg} from '#components'

interface ProductBannerProps {
    theme: 'DARK' | 'LIGHT';
    contentFlow: 'NORMAL' | 'REVERSE';
    contentAlignment: 'LEFT' | 'CENTER';
    productSku: string;
    img?: string;
    showAddToCartButton: boolean;
}

const props = defineProps<ProductBannerProps>();

const toastStore = useToastStore();
const addToCart = (productId: number | undefined) => {
    if (!!productId) {
        siteService().addToCart(productId)
            .then(() => toastStore.publish('Added to cart', 'success'))
            .catch(() => toastStore.publish('Failed to add to cart', 'error'));
    } else {
        toastStore.publish('Failed to add to cart', 'error');
    }
}

// fetch product data
const {data: productData} = await siteService().fetchProductBySku(props.productSku);

// Dynamic styles
const containerStyle = computed(() => {
    return {
        'bg-[#ffffff]': props.theme === 'LIGHT',
        'bg-[#191919] text-white': props.theme === 'DARK',
        'flex-col md:flex-row': props.contentFlow === 'NORMAL',
        'flex-col-reverse md:flex-row-reverse': props.contentFlow === 'REVERSE',
    };
});

const contentStyle = computed(() => {
    return {
        'items-center text-center': props.contentAlignment === 'CENTER',
    };
});

</script>