<template>
    <div class="flex md:px-20" :class="contentSlideStyle">
        <div class="p-[32px] flex flex-col justify-center grow shrink basis-0"
             :class="contentTextStyle">
            <h2 class="text-[32px] lg:text-[48px] font-medium">
                {{header1 }}</h2>
            <h2 class="text-[32px] lg:text-[48px] font-medium" v-if="header2">
                {{ header2 }}</h2>
            <p class="text-[14px] lg:text-[18px] my-[32px] font-normal" v-if="subTitle">
                {{ subTitle }}</p>

            <div class="w-full" v-if="callToAction?.url || callToAction?.label">
                <Button :theme="theme" size="md"
                        @click="navigateTo(callToAction.url, { external: true, open: { target: callToAction.openInNewTab ? '_blank' : '_top' } })"
                        >
                    {{ callToAction.label }}
                </Button>
            </div>
        </div>

        <div class="p-[32px] grow shrink basis-0 content-center relative" v-if="media">
            <NuxtImg class="m-auto md:w-full" :src="media.url" :alt="media.altText"
                     v-if="media.type == 'IMAGE'"/>
            <video autoplay muted="true" playsinline loop class="m-auto" v-else-if="media.type == 'VIDEO'">
                <source :src="media.url" type="video/mp4">
            </video>
            <div class="absolute top-[32px] left-[32px] bottom-[32px] right-[32px] bg-gradient-to-br from-10% from-[#000]/0 to-100%"
                 :style="[getGradientStyling(media.tintColor)]"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {navigateTo} from '#app'
import Button from '~/components/ui/atom/button.vue'
import {NuxtImg} from '#components'

interface StoryCardCallToAction {
    url: string
    label: string
    openInNewTab: boolean
}

interface StoryCardMedia {
    url: string
    altText: string
    type: "IMAGE" | "VIDEO"
    tintColor: string
}

export interface StoryCardProps {
    header1?: string
    header2?: string
    subTitle?: string
    callToAction?: StoryCardCallToAction
    media?: StoryCardMedia
    theme?: "DARK" | "LIGHT"
    textPosition?: "LEFT" | "RIGHT" | "CENTER"
    contentFlow?: "NORMAL" | "REVERSE"
    contentFlowMobile?: "NORMAL" | "REVERSE"
}

const {
  header1,
  header2,
  subTitle,
  callToAction,
  media,
  theme="LIGHT",
  textPosition = "LEFT",
  contentFlow = "NORMAL",
  contentFlowMobile = "NORMAL",
} = defineProps<StoryCardProps>()

const contentSlideStyle = computed(() => {
    return {
        'flex-col': contentFlowMobile === 'NORMAL',
        'flex-col-reverse': contentFlowMobile === 'REVERSE',
        'md:flex-row': contentFlow === 'NORMAL',
        'md:flex-row-reverse': contentFlow === 'REVERSE',
    }
})

const contentTextStyle = computed(() => {
    return {
        'text-left': textPosition === 'LEFT',
        'text-right': textPosition === 'RIGHT',
        'text-center': textPosition === 'CENTER'
    }
})

// can't use dynamic classes with tailwind, have to use styling
const getGradientStyling = (color) => {
  return [
    "--tw-gradient-from: "+color+" var(--tw-gradient-from-position);"
  ]
}

</script>