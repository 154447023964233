<template>
  <div class="flex flex-col p-8 w-auto max-w-[430px] md:max-w-[1440px]" :class="themeStyle">
    <hr class="border-black">
    <div class="flex flex-row justify-between my-[32px] text-4xl lg:text-6xl">
      <h2>FAQ</h2>
      <Icon :icon="isExpanded ? 'minus' : 'plus'" @click="toggleExpanded"/>
    </div>
    <div class="flex-row flex-wrap justify-between" :class="isExpanded ? 'flex' : 'hidden'">
      <div v-for="faq in props.faqs" class="mb-[32px] flex-initial basis-full md:basis-[45%]">
        <p class="text-2xl font-bold mb-[32px]">{{faq.question}}</p>
        <p class="text-xl">{{faq.answer}}</p>
      </div>
    </div>
    <hr class="border-black">
  </div>
</template>

<script setup lang="ts">

import Icon from "~/components/ui/atom/icon.vue";

interface faq {
  question: string;
  answer: string;
}

interface faqs {
  theme: "DARK" | "LIGHT";
  faqs: faq[];
}

const props = defineProps<faqs>();

const textColor = () => {return props.theme === "DARK" ? "text-white" : "text-black";}
const backgroundColor = () => {return props.theme === "DARK" ? "bg-black" : "bg-white";}
const height = () => {return isExpanded ? 'h-auto' : 'h-[138px] lg: h-[268px]'}
const themeStyle = computed(() => {
  return `${backgroundColor()} ${textColor()} ${height()}`;
})

let isExpanded = ref(false)

const toggleExpanded = () => isExpanded.value = !isExpanded.value
</script>

<style scoped>

</style>