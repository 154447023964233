<template>
    <!-- Carousel slide selector/picker -->
    <div class="w-full flex gap-4 justify-center flex-wrap">
        <button :aria-label="'Go to slide '+(i)" :aria-current="currentSlide === (i)"
                @click="$emit('onSelectSlide', (i))"
                v-for="i in slideCount">
            <Icon icon='circle' :class="contentCarouselTabStyle(i)"/>
        </button>
    </div>
</template>

<script setup lang="ts">
import Icon from '~/components/ui/atom/icon.vue';

interface CarouselSlideSelectorProps {
    slideCount: number;
    currentSlide: number;
    theme?: "DARK" | "LIGHT";
}

const {slideCount=1, currentSlide, theme="LIGHT"} = defineProps<CarouselSlideSelectorProps>()

const emits = defineEmits(['onSelectSlide']);

const contentCarouselTabStyle = (slideNum:number) => {
    return {
        'text-gray-300 text-opacity-50': currentSlide !== slideNum,
        'text-white': currentSlide === slideNum && theme === 'DARK',
        'text-black': currentSlide === slideNum && theme === 'LIGHT',
    };
}

</script>