<template>
  <div class="h-[372px] xl:h-[720px] w-full relative">
    <video v-if="mobileVideo || desktopVideo"
           autoplay
           muted="true"
           playsinline
           loop
           class="absolute w-full h-full object-cover z-20 motion-reduce:!hidden"
           aria-hidden="true">
      <!--      These won't switch src like image when resizing the window, which is just due to how browsers work.
          This likely won't be an issue for 99.9% of users   -->
      <!--      If they only provided one of either a mobile or desktop video, use the other as the fallback-->
      <source :media="'(max-width:' + (mobileMediaBreakpoint === 'tablet' ? '1280px' : '768px') + ')'"
        :src="mobileVideo ?? desktopVideo">
      <source :src="desktopVideo ?? mobileVideo">
    </video>
    <picture v-if="mobileImage || desktopImage">
      <!--      Unlike video, these will switch src dynamically when the viewport resizes (someone changes window size) -->
      <!--      If they only provided one of either a mobile or desktop image, use the other as the fallback -->
      <source :media="'(max-width:' + (mobileMediaBreakpoint === 'tablet' ? '1280px' : '768px') + ')'"
        :srcset="mobileImage ?? desktopImage">
      <img class="absolute w-full h-full object-cover z-10" :src="desktopImage ?? mobileImage" alt=""
        aria-hidden="true" />
    </picture>
    <div v-if="header?.line1"
         class="absolute w-full h-full flex flex-col leading-110 mb-[32px] bg-gradient-to-br from-10% from-[#000]/0 to-100% z-30"
         :class="headerPosition"
         :style="[getTextColorStyling(header.textColor), getGradientStyling(tintColor)]">
      <div class="w-fit flex flex-col px-8 sm:px-16" :class="internalAlignment">
        <span v-if="header?.topSubtext" class="text-xl sm:text-4xl font-medium mb-1"
          :style="getTextColorStyling(header.textColor)">{{header.topSubtext}}</span>
        <span class="mb-6 sm:mb-8 font-medium" :class="headerTextSize">{{header?.line1}}</span>
        <span class="mb-6 sm:mb-8 font-medium" :class="headerTextSize" v-if="header?.line2">{{header?.line2}}</span>
        <span v-if="header?.bottomSubtext" class="text-xl sm:text-4xl mb-1 font-medium"
          :style="getTextColorStyling(header.textColor)">{{header.bottomSubtext}}</span>
        <div v-if="header?.buttons && header?.buttons.length > 0"
          class="flex flex-col sm:flex-row w-full my-4 sm:my-6" :class="buttonAlignment">
          <div v-for="button in header?.buttons">
            <Button :theme="button.buttonTheme" size="lg"
              @click.prevent="navigateTo(button?.link ?? '/', { external: true })">
              {{ button.text }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {navigateTo} from "#app";
import Button from '~/components/ui/atom/button.vue'

interface ActionButton {
    text: string,
    link: string,
    buttonTheme: "DARK" | "LIGHT";
}

export interface Header {
  line1: string,
  line2: string,
  textColor: string,
  textSize: string,
  horizontalPosition: string,
  verticalPosition: string
  topSubtext: string,
  bottomSubtext: string,
  buttons: ActionButton[]
}

interface HeroCardProps {
  desktopVideo: string
  mobileVideo: string
  desktopImage: string
  mobileImage: string
  mobileMediaBreakpoint: string
  header: Header
  tintColor?: string
}

const {
  desktopVideo,
    mobileVideo,
    desktopImage,
    mobileImage,
    mobileMediaBreakpoint,
    header,
    tintColor = '#000000',
} = defineProps<HeroCardProps>()

const headerTextSize = computed(() => {
    return {
        'text-xl lg:text-5xl': header?.textSize === 'small',
        'text-2xl lg:text-6xl': header?.textSize === 'medium',
        'text-3xl lg:text-7xl': header?.textSize === 'large',
        'text-4xl lg:text-8xl': header?.textSize === 'XL',
        'text-5xl lg:text-9xl': header?.textSize === '2XL'
    }
})

const headerPosition = computed(() => {
    return {
        'justify-center': header?.verticalPosition === 'center',
        'justify-end': header?.verticalPosition === 'bottom',
        'justify-start': header?.verticalPosition === 'start',

        'items-center': header?.horizontalPosition === 'center',
        'items-end': header?.horizontalPosition === 'right',
        'items-start': header?.horizontalPosition === 'left'
    }
})

const internalAlignment = computed(() => {
    return {
        'items-center': header?.horizontalPosition === 'center',
        'items-end': header?.horizontalPosition === 'right',
        'items-start': header?.horizontalPosition === 'left'
    }
})

const buttonAlignment = computed(() => {
  return {
    'items-center sm:justify-center': header?.horizontalPosition === 'center',
    'items-end sm:justify-end': header?.horizontalPosition === 'right',
    'items-start sm:justify-start': header?.horizontalPosition === 'left'
  }
})

// can't use dynamic classes with tailwind, have to use styling
const getTextColorStyling = (color) => {
  return {color: color || 'none'}
}

const getGradientStyling = (color) => {
  return [
    "--tw-gradient-from: "+color+" var(--tw-gradient-from-position);"
  ]
}

</script>