<template>
  <form class="mx-1 my-3">
    <div class="flex gap-2 w-full" v-if="!promoApplied">
      <input aria-label="Enter your email address"
             placeholder="Email Address"
             type="email"
             class="flex-auto px-2 border border-gray-300 bg-white"
             v-model="email" :bind="emailAttrs" :disabled="isSubmitting" />
      <button :aria-label=buttonText
              class="flex-none bg-black text-white py-2 px-4 transition-colors ease-in duration-100 focus:bg-gray-700 hover:bg-gray-700"
              @click="submitEmailSignup" :disabled="isSubmitting || promoApplied">
        {{buttonText}}
        <Icon v-if="isSubmitting" icon="fa-circle-notch" color="#fff" class="animate-spin" size="sm" />
      </button>
    </div>
    <div class="bg-green-300 text-center p-3 mt-1" v-if="promoApplied">{{ successMessage }}</div>
    <div class="bg-red-300 text-center p-3 mt-1" v-if="responseReceived && !promoApplied">{{ errorMessage }}</div>
    <div class="bg-gray-300 text-center p-3 mt-1"
         v-if="!!errors.email">{{errors.email}}</div>
  </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate'
import {z} from 'zod'
import {toTypedSchema} from '@vee-validate/zod'
import debounce from 'debounce'
import Icon from '~/components/ui/atom/icon.vue'

const responseReceived = ref(false)

// validation
const invalidEmailMessage = "Invalid Email Address";
const validationSchema = toTypedSchema(
    z.object({
      email: z.string({message: invalidEmailMessage}).email(invalidEmailMessage)
    })
);
// define fields using our validation schema
const {errors, isSubmitting, handleSubmit, defineField} = useForm({validationSchema});
const [email, emailAttrs] = defineField('email');

interface UrlQueryParam {
  key: string
  value: string
}

interface EmailSignupForm {
  buttonText: string
  successMessage: string
  errorMessage: string
  url: string
  methodType: 'GET' | 'POST'
  urlParams?: UrlQueryParam[]
  bodyData?: string
}

interface PartnerEmailFormCardProps extends EmailSignupForm {
}

const emit = defineEmits(['onSuccess', 'onError'])
const {
  buttonText,
  successMessage,
  errorMessage,
  url,
  methodType,
  urlParams,
  bodyData
} = defineProps<PartnerEmailFormCardProps>()

const promoApplied = ref(false);

const submitEmailSignup = handleSubmit(debounce(values => {
  responseReceived.value = false

  const queryParams = {
    email: email.value
  };
  if (!!urlParams) {
    urlParams.filter(val => !!val.key && !!val.value)
            .forEach(val => queryParams[val.key] = val.value);
  }

  $fetch(url, {
    method: methodType,
    body: methodType != 'GET' ? bodyData : undefined,
    query: queryParams
  })
      .then(res => {
        promoApplied.value = true;
        emit('onSuccess', res)
      })
      .catch(err => {
        promoApplied.value = false;
        emit('onError', err)
      })
      .finally(() => responseReceived.value = true);
}, 200, { immediate: true }));

</script>
