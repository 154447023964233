<template>
    <div><NuxtLink class="hover:text-inherit hover:cursor-pointer"
                   :to="url"
                   :aria-label="ariaLinkDescription"
                   external>
        <!-- Product image -->
        <div tabindex="0" role="group" class="max-w-[344px] relative bg-gray-300 bg-opacity-25 group">
            <NuxtImg :src="img" :alt="imgAlt !== '' ? imgAlt : name" />
            <span v-show="tag" class="absolute top-3 right-3 px-2 py-1 text-sm" :style="contentTagStyle">
                {{ tag }}
            </span>

            <Button @click.prevent="addToCart(id)"
                    :theme="theme"
                    class="absolute bottom-3 right-3 rounded-lg"
                    size="sm"
                    aria-label="Add to Cart"
                    role="button">
              <Icon icon="cart-plus" />
            </Button>
        </div>
        <!-- Product info -->
        <div class="py-3">
            <h3 class="font-bold text-[16px]">{{ name }}</h3>
            <p>{{ info }}</p>
            <StarRating class="my-1" :rating="rating"/>
            <p class="font-bold">{{ price }}</p>
        </div>
    </NuxtLink></div>
</template>

<script setup lang="ts">
import {useToastStore} from '~/stores/toastStore'
import Button from '~/components/ui/atom/button.vue'
import Icon from '~/components/ui/atom/icon.vue'
import StarRating from '~/components/ui/atom/starRating.vue'
import {NuxtImg, NuxtLink} from '#components'

export interface ProductCardProps {
    id: number;
    name: string;
    tag?: string;
    tagColor?: string;
    tagTextColor?: string;
    info: string;
    rating: number;
    price: string;
    img: string;
    imgAlt?: string;
    url?: string;
    theme?: "DARK" | "LIGHT";
}

const {
    id,
    name,
    tag,
    tagColor,
    tagTextColor,
    info,
    rating,
    price,
    img,
    imgAlt="",
    url="",
    theme="LIGHT"
} = defineProps<ProductCardProps>();

const toastStore = useToastStore();
const addToCart = (productId: number) => {
    siteService().addToCart(productId)
        .then(() => toastStore.publish('Added to cart', 'success'))
        .catch(() => toastStore.publish('Failed to add to cart', 'error'));
}

const ariaLinkDescription= computed(() => {
  const description = Array<String>()

  description.push(name);
  description.push(info);
  if (price) description.push(price);
  if (tag) description.push(tag);
  if (rating) description.push(`${rating} Stars`);

  return description.join(" ");
})
const contentTagStyle = computed(() => {
    return {backgroundColor: tagColor || '#000000', color: tagTextColor || '#FFFFFF'};
});

</script>