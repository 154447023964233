<template>
  <NuxtLink class="group" :href="url" :aria-label="title" external>
    <Icon :class="linkHoverStyle" class="text-4xl mb-4" v-if="symbol" :icon="symbol" :pack-prefix="getSymbolStyle(style)"/>
    <p class="font-medium text-lg sm:text-xl mb-0 sm:mb-2">{{ title }}</p>
    <p :class="linkHoverStyle" class="text-lg sm:text-xl">{{ body }}</p>
  </NuxtLink>
</template>

<script setup lang="ts">
import Icon from '~/components/ui/atom/icon.vue'
import {NuxtLink} from '#components'

export interface IconTileProps {
  symbol?: string;
  style?: "LIGHT" | "REGULAR" | "SOLID";
  theme?: "DARK" | "LIGHT";
  title: string;
  body: string;
  url?: string;
}

const {symbol, style = "REGULAR", theme = "LIGHT", title, body, url} = defineProps<IconTileProps>();

const linkHoverStyle = computed(()=>{
  const textColor = theme === "DARK" ? "text-white" : "text-black"
  return 'group-hover:'+textColor
})

const getSymbolStyle = (symbolStyle: string) => {
  switch (symbolStyle) {
    case "LIGHT":
      return 'fal'
    case "SOLID":
      return 'fas'
    case "REGULAR":
    default:
      return 'far'
  }
}

</script>