<template>
    <Carousel :totalItems="slides.length"
              :theme="theme"
              :show-slide-selector="false"
              :page-size=1
              v-slot="{index, key}">
        <div :class="contentCarouselStyle">
          <StoryCard :key="key" v-bind="slides[index]" />
        </div>
    </Carousel>
</template>

<script setup lang="ts">
import Carousel from '~/components/ui/molecule/carousel/index.vue'
import StoryCard, {type StoryCardProps} from '~/components/ui/organism/story/card.vue'

interface StoryCarouselProps {
  slides: StoryCardProps[];
  theme?: "DARK" | "LIGHT";
}

const {
  slides=[],
  theme="LIGHT",
} = defineProps<StoryCarouselProps>()

/* Dynamic styles */
const contentCarouselStyle = computed(() => {
  return {
    'bg-[#ffffff]': theme === 'LIGHT',
    'bg-[#191919] text-white': theme === 'DARK'
  }
})

</script>