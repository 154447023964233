<template>
  <div :class="containerStyle" class="py-[50px] px-[25px] sm:px-[100px] flex flex-col text-center">
    <h2 class="mb-[64px] text-5xl font-medium" v-if="props.header">{{ props.header }}</h2>
    <div class="mb-[32px] flex flex-col items-baseline sm:flex-row" :class="rowAlignment" v-if="props.tiles && props.tiles.length > 0">
      <div class="mx-0 mb-5 sm:mb-0 flex flex-col w-auto items-center" :class="tileBasis"
           v-for="(tile, index) in props.tiles" :key="index">
        <IconTile v-bind='tile' :theme="props.theme" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconTile, {type IconTileProps} from '~/components/ui/atom/icon-tile.vue'

interface ValuePropositionCard {
    header?: string;
    tiles: IconTileProps[];
    theme: "DARK" | "LIGHT";
}

const props = defineProps<ValuePropositionCard>();

const textColor = () => {
    return props.theme === "DARK" ? "text-white" : "text-black";
}
const backgroundColor = () => {
    return props.theme === "DARK" ? "bg-black" : "bg-white";
}

const containerStyle = computed(() => {
  return `${backgroundColor()} ${textColor()}`
})

const tileBasis = computed(() => {
  return {
    'sm:basis-1/2':props?.tiles?.length <= 1,
    'sm:basis-1/3':props?.tiles?.length == 2,
    'sm:basis-1/4':props?.tiles?.length == 3,
    'sm:basis-1/5':props?.tiles?.length == 4,
    'sm:basis-1/6':props?.tiles?.length >= 5,
  }
})

const rowAlignment = computed(() => {
  return {'sm:justify-between': props?.tiles?.length > 2,
          'sm:justify-center': props?.tiles?.length == 1,
          'sm:justify-around': props?.tiles?.length == 2
  }
})
</script>

<style scoped>

</style>