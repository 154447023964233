<template>
  <div class="text-center w-full md:p-[100px] font-medium" :class="themeStyle">
    <h2 class="mb-[32px] text-[32px] md:text-[40px]">{{props.headline}}</h2>
    <h3 class="mb-[32px] text-[24px]">{{props.subHeadline}}</h3>
    <div>
      <Button @click="goToCtaUrl" :class="ctaButtonStyle" :theme="props.theme" size="lg" v-if="showCtaButton">
        {{props.callToActionText}}
      </Button>
      <a v-else-if="showCtaLink" :class="ctaLinkStyle" :href="props.callToActionUrl">{{props.callToActionText}}</a>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '~/components/ui/atom/button.vue'

interface HeadlineProps {
  headline: string;
  subHeadline: string;
  theme: "LIGHT" | "DARK";
  callToActionType: "BUTTON" | "LINK";
  callToActionText: string;
  callToActionUrl: string;
}

const props = defineProps<HeadlineProps>();

const goToCtaUrl = () => {
  window.location.replace(props.callToActionUrl);
}

const textColor = () => {return props.theme === "DARK" ? "text-white" : "text-black";}
const backgroundColor = () => {return props.theme === "DARK" ? "bg-black" : "bg-white";}

const themeStyle = computed(() => {
  return `${backgroundColor()} ${textColor()}`;
})


const ctaButtonStyle = computed(() => {
  //smaller on tablet
  const bgColor = backgroundColor() === "bg-black" ? "bg-white" : "bg-black";
  const txtColor = textColor() === "text-black" ? "text-white" : "text-black";

  return `${bgColor} ${txtColor} text-[16px] py-[10px] px-[20px] md:text-[14px] md:py-[6px] md:px-[16px]`;
})

const ctaLinkStyle = computed(() => {
  return `${textColor()} underline text-[16px]`;
})

const showCtaButton = computed(() => {
  return props.callToActionType === 'BUTTON' && props.callToActionText && props.callToActionUrl;
})

const showCtaLink = computed(() =>{
  return props.callToActionType === 'LINK' && props.callToActionText && props.callToActionUrl;
})

</script>