<template>
    <div class="contents">
        <Content v-if="canShowContent"
                 :model="model"
                 :content="builderContent"
                 :api-key="apiKey"
                 :customComponents="loadedComponents"/>
    </div>
</template>

<script setup lang="ts">
import {loadedComponents} from "~/composables/builderIoComponents"
import {Content, isPreviewing} from "@builder.io/sdk-vue";

interface BuilderContentProps {
    model: string;
}

const emit = defineEmits(['onLoad', 'onError']);
const props = defineProps<BuilderContentProps>();
const apiKey = builderIoService().getPublicApiKey();
const route = useRoute();

// make a call to builder for the provided model
const {
    data: builderContent,
    status: builderContentStatus,
    refresh: refreshBuilderContent
} = await useAsyncData(() => builderIoService().fetchOneModel(props.model, route.path));
const canShowContent = computed<boolean>(() => builderContent.value != null || isPreviewing());

const refresh = async () => {
    await refreshBuilderContent();
    handleBuilderContentResult();
}

const handleBuilderContentResult = () => {
    if (builderContentStatus.value === 'pending') return;

    if (builderContentStatus.value === 'success') {
        emit('onLoad', builderContent.value);
    } else {
        emit('onError');
    }
}

// immediately handle results
handleBuilderContentResult();

</script>