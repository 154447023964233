/*
    This file is for defining custom components used in Builder.IO.
    https://www.builder.io/c/docs/custom-components-setup#registering-your-component
*/

import HeroCard from "~/components/ui/molecule/heroCard.vue";
import StoryCarousel from "~/components/ui/organism/story/carousel.vue";
import ProductCarousel from "~/components/ui/organism/product/carousel.vue";
import ProductBanner from "~/components/ui/organism/product/banner.vue";
import ArticleCarousel from "~/components/ui/organism/article/carousel.vue";
import Headline from "~/components/ui/atom/headline.vue";
import ValueProposition from "~/components/ui/molecule/value-proposition/index.vue";
import Faq from "~/components/ui/molecule/faq.vue";
import ProductComparison from "~/components/ui/organism/product/comparison.vue";
import PartnerEmailFormCard from "~/components/ui/organism/partnerEmailFormCard.vue";

export const loadedComponents = [
        {
            component: HeroCard,
            name: "Hero Card",
            inputs: [
                {
                    name: 'desktopVideo',
                    type: 'string',
                    defaultValue: '',
                },
                {
                    name: 'mobileVideo',
                    type: 'string',
                    defaultValue: '',
                    helperText: 'Videos will be hidden if a user has set up their browser to display less motion. When a video is hidden, an image can be shown instead. Consider adding an image to display as an alternative when using a video.',
                },
                {
                    name: 'tintColor',
                    type: 'color',
                    helperText: 'A color to use as gradient tint overlay starting in top-left and ending transparent at bottom-right of a Video/Image.'
                },
                {
                    name: 'desktopImage',
                    type: 'string',
                    defaultValue: '',
                },
                {
                    name: 'mobileImage',
                    type: 'string',
                    defaultValue: '',
                    helperText: 'If using an image you must provide both a desktop and mobile image. If using the same image put the same url in both boxes.'
                },
                {
                    name: 'mobileMediaBreakpoint',
                    type: 'string',
                    enum: ['mobile', 'tablet'],
                    helperText: 'If mobile, then mobile image/video is only shown on phone-sized screens. If tablet then mobile image/video is also shown on phone and tablet sized screen.',
                    defaultValue: 'mobile'
                },
                {
                    name: 'header',
                    type: 'object',
                    defaultValue: {
                        horizontalPosition: 'left',
                        verticalPosition: 'top',
                        textSize: 'medium'
                    },
                    subFields: [
                        {
                            name: 'line1',
                            type: 'string',
                            required: true,
                            helperText: '40 character max.',
                        },
                        {
                            name: 'line2',
                            type: 'string',
                            required: false,
                            helperText: '40 character max. Will not display if line 1 is blank.',
                        },
                        {
                            name: 'textSize',
                            type: 'string',
                            enum: ['small', 'medium', 'large', 'XL', '2XL'],
                        },
                        {
                            name: 'textColor',
                            type: 'color',
                        },
                        {
                            name: 'horizontalPosition',
                            type: 'string',
                            enum: ['left', 'center', 'right']
                        },
                        {
                            name: 'verticalPosition',
                            type: 'string',
                            enum: ['top', 'center', 'bottom']
                        },
                        {
                            name: 'topSubtext',
                            type: 'string',
                            helperText: 'Will not display if line 1 is blank.'
                        },
                        {
                            name: 'bottomSubtext',
                            type: 'string',
                            helperText: 'Will not display if line 1 is blank.'
                        },
                        {
                            name: 'buttons',
                            type: 'list',
                            subFields: [
                                {
                                    name: 'text',
                                    type: 'string',
                                    required: true
                                },
                                {
                                    name: 'link',
                                    type: 'string',
                                    helperText: 'This should be an internal link. Use /products instead of thorne.com/products',
                                    required: true
                                },
                                {
                                    name: 'buttonTheme',
                                    type: 'string',
                                    enum: ['LIGHT', 'DARK'],
                                    defaultValue: 'LIGHT'
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        {
            component: StoryCarousel,
            name: "Story Card",
            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'DARK',
                    required: true
                },
                {
                    name: 'slides',
                    type: 'list',
                    required: true,
                    subFields: [
                        {
                            name: 'header1',
                            type: 'string',
                            required: true,
                            helperText: '40 Characters Max'
                        },
                        {
                            name: 'header2',
                            type: 'string',
                            required: false,
                            helperText: '40 Characters Max'
                        },
                        {
                            name: 'subTitle',
                            type: 'string',
                            required: true,
                            helperText: 'Recommended no more than 100 characters (including spaces)'
                        },
                        {
                            name: 'textPosition',
                            type: 'string',
                            enum: ['LEFT', 'CENTER', 'RIGHT'],
                            defaultValue: 'LEFT',
                            required: true
                        },
                        {
                            name: 'contentFlow',
                            type: 'string',
                            enum: ['NORMAL', 'REVERSE'],
                            defaultValue: 'NORMAL',
                            required: true
                        },
                        {
                            name: 'contentFlowMobile',
                            type: 'string',
                            enum: ['NORMAL', 'REVERSE'],
                            defaultValue: 'NORMAL',
                            required: true
                        },
                        {
                            name: 'callToAction',
                            type: 'object',
                            subFields: [
                                {
                                    name: 'url',
                                    type: 'url',
                                    required: true
                                },
                                {
                                    name: 'label',
                                    type: 'string',
                                    required: true
                                },
                                {
                                    name: 'openInNewTab',
                                    type: 'boolean',
                                    required: true
                                }
                            ]
                        },
                        {
                            name: 'media',
                            type: 'object',
                            subFields: [
                                {
                                    name: 'url',
                                    type: 'url',
                                    required: true
                                },
                                {
                                    name: 'type',
                                    type: 'string',
                                    enum: ['IMAGE', 'VIDEO'],
                                    required: true
                                },
                                {
                                    name: 'altText',
                                    type: 'string',
                                    required: true
                                },
                                {
                                    name: 'tintColor',
                                    type: 'color',
                                    helperText: 'A color to use as gradient tint overlay starting in top-left and ending transparent at bottom-right of a Video/Image.'
                                },
                            ]
                        }
                    ]
                },
            ]
        },

        {
            component: ProductCarousel,
            name: "Product Carousel",
            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'LIGHT',
                    required: true
                },
                {
                    name: 'title',
                    type: 'string',
                    required: false,
                    onChange: (options) => {
                        const MAX_LEN = 40;

                        if (options.get('title').length > MAX_LEN) {
                            alert(`maximum title length is ${MAX_LEN} characters`)
                        }
                    }
                },
                // Note: Eventually we'll swap out fields for a plugin where products can be selected
                {
                    name: 'products',
                    type: 'list',
                    required: true,
                    subFields: [
                        {
                            name: 'id',
                            type: 'number',
                            required: true,
                        },
                        {
                            name: 'name',
                            type: 'string',
                            required: true,
                        },
                        {
                            name: 'info',
                            type: 'string',
                            required: true
                        },
                        {
                            name: 'price',
                            type: 'string',
                            required: true,
                        },
                        {
                            name: 'rating',
                            type: 'number',
                            required: true,
                            onChange: (options) => {
                                const MAX_STARS = 5;

                                if (options.get('rating') > MAX_STARS || options.get('rating') < 0) {
                                    alert(`rating must be between 0 and ${MAX_STARS}`)
                                }
                            }
                        },
                        {
                            name: 'tag',
                            type: 'string',
                        },
                        {
                            name: 'tagColor',
                            type: 'color',
                            showIf: (options, parent, parentElements) => !!options.get('tag')
                        },
                        {
                            name: 'tagTextColor',
                            type: 'color',
                            showIf: (options, parent, parentElements) => !!options.get('tag')
                        },
                        {
                            name: 'url',
                            type: 'url',
                            required: true,
                        },
                        {
                            name: 'img',
                            type: 'url',
                            required: true
                        },
                        {
                            name: 'imgAlt',
                            type: 'string',
                            required: true
                        }
                    ]
                }
            ]
        },

        {
            component: ArticleCarousel,
            name: "Article Carousel",
            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'LIGHT',
                    required: true
                },
                {
                    name: 'title',
                    type: 'string',
                    required: false,
                    onChange: (options) => {
                        const MAX_LEN = 40;

                        if (options.get('title').length > MAX_LEN) {
                            alert(`maximum title length is ${MAX_LEN} characters`)
                        }
                    }
                },
                // Note: This is v1 of this component, eventually we'll swap out fields for a plugin where articles can be selected
                {
                    name: 'articles',
                    type: 'list',
                    required: true,
                    subFields: [
                        {
                            name: 'title',
                            type: 'string',
                            required: true
                        },
                        {
                            name: 'url',
                            type: 'url',
                            required: true,
                        },
                        {
                            name: 'img',
                            type: 'url',
                            required: true
                        },
                        {
                            name: 'imgAlt',
                            type: 'string',
                            required: true
                        }
                    ]
                }
            ]
        },

        {
            component: Headline,
            name: "Headline",
            inputs: [
                {
                    name: 'headline',
                    type: 'string',
                    helperText: '40 character max.',
                    defaultValue: '',
                    onChange: (options) => {
                        const MAX_LEN = 40;

                        if (options.get('headline').length > MAX_LEN) {
                            alert(`maximum headline length is ${MAX_LEN} characters`)
                        }
                    }
                },
                {
                    name: 'subHeadline',
                    type: 'string',
                    helperText: '240 character max.',
                    defaultValue: '',
                    onChange: (options) => {
                        const MAX_LEN = 240;

                        if (options.get('subHeadline').length > MAX_LEN) {
                            alert(`maximum sub-headline length is ${MAX_LEN} characters`)
                        }
                    }
                },
                {
                    name: 'callToActionText',
                    type: 'string',
                    helperText: 'If no value is provided, no call to action will be shown.',
                    defaultValue: ''
                },
                {
                    name: 'callToActionUrl',
                    type: 'string',
                    helperText: 'If no value is provided, no call to action will be shown.',
                    defaultValue: ''
                },
                {
                    name: 'callToActionType',
                    type: 'string',
                    enum: ['BUTTON', 'LINK'],
                    defaultValue: 'BUTTON'
                },
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['LIGHT', 'DARK'],
                    defaultValue: 'LIGHT',
                }
            ]
        },

        {
            component: ValueProposition,
            name: 'Value Proposition',
            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'LIGHT',
                    required: true
                },
                {
                    name: 'header',
                    type: 'string',
                    helperText: '40 characters max',
                    required: false,
                    onChange: (options) => {
                        const MAX_LEN = 40;

                        if (options.get('header').length > MAX_LEN) {
                            alert(`Maximum header length is ${MAX_LEN} characters`);
                            options.set('header', options.get('header').slice(0, 40));
                        }
                    }
                },
                {
                    name: 'tiles',
                    type: 'list',
                    required: false,
                    helperText: 'Recommended no more than 4 tiles',
                    subFields: [
                        {
                            name: 'symbol',
                            type: 'string',
                            enum: ['list-check', 'capsules', 'handshake', 'microscope'],
                            helperText: 'If other icon choices are required contact software engineering',
                            required: false
                        },
                        {
                            name: 'style',
                            friendlyName: 'Symbol Style',
                            type: 'string',
                            enum: ['REGULAR', 'SOLID', 'LIGHT'],
                            default: 'LIGHT',
                            helperText: 'If other style choices are required contact software engineering',
                            required: false
                        },
                        {
                            name: 'title',
                            type: 'string',
                            required: true
                        },
                        {
                            name: 'body',
                            type: 'string',
                            helperText: '60 characters max',
                            required: false
                            //onChange doesn't seem to be supported at this time for list subFields - if it becomes available here we should add it for character count
                        },
                        {
                            name: 'url',
                            type: 'url',
                            required: false,
                        },
                    ]
                }
            ]
        },

        {
            component: Faq,
            name: 'FAQ',
            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'LIGHT',
                    required: true
                },
                {
                    name: 'faqs',
                    type: 'list',
                    required: true,
                    subFields: [
                        {
                            name: 'question',
                            type: 'string',
                            helperText: '40 characters max',
                            required: true
                            //onChange doesn't seem to be supported at this time for list subFields - if it becomes available here we should add it for character count
                        },
                        {
                            name: 'answer',
                            type: 'string',
                            required: true
                            //onChange doesn't seem to be supported at this time for list subFields - if it becomes available here we should add it for character count
                        }
                    ]
                }
            ]
        },

        {
            component: ProductBanner,
            name: 'Product Banner',
            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'LIGHT'
                },

                {
                    name: 'contentFlow',
                    type: 'string',
                    enum: ['NORMAL', 'REVERSE'],
                    defaultValue: 'NORMAL'
                },

                {
                    name: 'contentAlignment',
                    type: 'string',
                    enum: ['LEFT', 'CENTER'],
                    defaultValue: 'LEFT'
                },

                {
                    name: 'productSku',
                    type: 'string',
                    required: true
                },

                {
                    name: 'img',
                    type: 'url',
                    helperText: 'Image to override the primary product image.'
                },

                {
                    name: 'showAddToCartButton',
                    type: 'boolean',
                    defaultValue: true
                }
            ]
        },
        {
            component: PartnerEmailFormCard,
            name: 'Partner Email Form Card',
            inputs: [
                {
                    name: 'buttonText',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'successMessage',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'errorMessage',
                    type: 'string',
                    required: true,
                },
                {
                    name: 'url',
                    type: 'url',
                    required: true,
                },
                {
                    name: 'methodType',
                    type: 'string',
                    enum: ['GET', 'POST'],
                    defaultValue: 'GET'
                },
                {
                    name: 'urlParams',
                    type: 'list',
                    subFields: [
                        {
                            name: 'key',
                            type: 'string',
                            required: true,
                            helperText: "This should be the key of the query parameter."
                        },
                        {
                            name: 'value',
                            type: 'string',
                            required: true,
                            helperTest: "This should be the value of the query parameter."
                        }
                    ]
                },
                {
                    name: 'bodyData',
                    type: 'string',
                },
            ]
        },
        {
            component: ProductComparison,
            name: 'Product Comparison',

            inputs: [
                {
                    name: 'theme',
                    type: 'string',
                    enum: ['DARK', 'LIGHT'],
                    defaultValue: 'LIGHT'
                },
                
                {
                    name: 'title',
                    type: 'string',
                    required: true,

                    onChange: (options) => {
                        const MAX_LEN = 50;
                        const title: string = options.get('title');

                        if (title.length > MAX_LEN) {
                            options.set('title', title.substring(0, MAX_LEN));
                            alert(`Maximum title length is ${MAX_LEN} characters`);
                        }
                    }
                },

                {
                    name: 'rows',
                    type: 'list',
                    required: true,
                    helperText: 'Rows used to represent value statements',

                    subFields: [
                        {
                            name: 'rowTitle',
                            type: 'string',
                            required: true,
                        }
                    ]
                },

                {
                    name: 'products',
                    type: 'list',
                    required: true,

                    onChange: (options) => {
                        const MAX_PRODUCTS = 4;
                        const products = options.get('products');

                        if (products.length > MAX_PRODUCTS) {
                            options.set('products', products.slice(0, MAX_PRODUCTS));
                            alert(`Products are limited to ${MAX_PRODUCTS} maximum`);
                        }
                    },

                    subFields: [
                        {
                            name: 'sku',
                            type: 'string',
                            required: true
                        },
                        {
                            name: 'productRows',
                            type: 'list',
                            required: true,
                            helperText: 'Product specific value statements, maps to previously defined rows',

                            subFields: [
                                {
                                    name: 'value',
                                    type: 'richText',
                                    required: true
                                }
                            ]
                        }
                    ]
                },
            ]
        }
];