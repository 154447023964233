<template>
    <div class="flex items-center gap-1">
        <div class="relative">
            <div class="flex text-gray-600">
                <Icon v-for="_ of MAX_STARS" icon='star'/>
            </div>
            <div class="flex absolute top-0 left-0">
                <Icon v-for="_ of fullStars" icon='star'/>
                <Icon v-if="hasHalfStar" icon='star-half'/>
            </div>
        </div>
        <div>
            {{ `(${rating ?? 0}/5)` }}
        </div>
    </div>
</template>

<script setup lang="ts">
import Icon from '~/components/ui/atom/icon.vue';

const MAX_STARS = 5;

interface StarRatingProps {
    rating: number;
}

const props = defineProps<StarRatingProps>();

const fullStars = computed(() => Math.min(Math.floor(props.rating), MAX_STARS));
const hasHalfStar = computed(() => props.rating % 1 >= 0.5);

</script>