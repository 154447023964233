<template>
    <button :class="ButtonVariants(buttonProps)">
        <slot></slot>
    </button>
</template>

<script setup lang="ts">
import { tv } from 'tailwind-variants';

const ButtonVariants = tv({
    base: 'font-bold',
    variants: {
        theme: {
            // empty as we have compound variants (below) based on a buttons theme and if it is outlined or not
            // but we still need to define these
            LIGHT: '',
            DARK: ''
        },

        outlined: {
            true: 'bg-transparent border'
        },

        size: {
            sm: 'text-[14px] py-[6px] px-[16px]',
            md: 'text-[16px] py-[6px] px-[16px]',
            lg: 'text-[16px] py-[10px] px-[20px]'
        }
    },
    compoundVariants: [
        {
            theme: 'LIGHT',
            outlined: true,
            class: 'text-[#424242] border-gray-800 hover:bg-transparent hover:text-[#757575] hover:border-[#757575] disabled:border-[#BDBDBD] disabled:text-[#BDBDBD] disabled:bg-transparent'
        },
        {
            theme: 'DARK',
            outlined: true,
            class: 'text-white border-white hover:bg-transparent hover:text-[#BDBDBD] hover:border-[#BDBDBD] disabled:border-[#464545] disabled:text-[#464545]'
        },
        {
            theme: 'LIGHT',
            outlined: false,
            class: 'text-white bg-[#424242] hover:bg-[#757575] disabled:bg-[#BDBDBD]'
        },
        {
            theme: 'DARK',
            outlined: false,
            class: 'text-[#555] bg-white hover:text-[#757575]'
        }
    ]
});

interface ButtonProps {
    theme: "LIGHT" | "DARK"
    size: "sm" | "md" | "lg"
    outlined?: boolean
}

const buttonProps = withDefaults(defineProps<ButtonProps>(), {
    outlined: false
})
</script>