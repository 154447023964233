<template>
    <div ref="carousel-container" class="w-full overflow-hidden flex justify-center items-stretch flex-row">
        <slot name="previous-controls" v-if="totalSlides > 1">
          <div class="flex mx-5 justify-center items-center"
               :class="paginationArrowsHidden?'hidden':''">
            <button class="w-14 h-14 rounded-full bg-white text-black drop-shadow-md z-10"
                    @click="previousSlide"
                    aria-label="Previous slide">
              <Icon class="text-xl mt-1.5" icon='arrow-left'/>
            </button>
          </div>
        </slot>
        <div ref="carousel-inner" class="w-full relative md:w-auto md:flex md:grow-[4]" :style="carouselHeight+' '+carouselWidth">
            <Transition :name="transitionName"
                        @before-enter="onBeforeEnter"
                        @after-enter="onAfterEnter">
              <div
                class="w-full h-full flex justify-center items-stretch gap-x-6"
                :key="currentSlide"
                @mousedown.prevent="startDragMeasure"
                @mouseup.prevent="endDragMeasure"
                @touchstart="startTouchMeasure"
                @touchend="endTouchMeasure"
                role="tabpanel">
                <slot
                    name="default"
                    v-for="index in itemIndexRangeForSlide(currentSlide)"
                    :key="index"
                    :index="index"
                />
              </div>
            </Transition>
        </div>
        <slot name="next-controls" v-if="totalSlides > 1">
          <div class="flex mx-5 justify-center items-center"
               :class="paginationArrowsHidden?'hidden':''">
            <button class="w-14 h-14 rounded-full bg-white text-black drop-shadow-md z-10"
                    @click="nextSlide"
                    aria-label="Next slide">
              <Icon class="text-xl mt-1.5" icon='arrow-right'/>
            </button>
          </div>
        </slot>
    </div>
    <slot name="pagination" v-if="showSlideSelector && totalSlides > 1">
        <!-- Slide selector controls -->
        <SlideSelector
            class="my-6"
            :theme="theme"
            :slide-count="totalSlides"
            :currentSlide="currentSlide"
            @on-select-slide="selectSlide"/>
    </slot>
</template>

<script setup lang="ts">
import Icon from '~/components/ui/atom/icon.vue'
import SlideSelector from '~/components/ui/molecule/carousel/slideSelector.vue'
import Transition from '@vue/runtime-core'

interface CarouselProps {
    pageSize?: number
    showSlideSelector?: boolean;
    theme?: "DARK" | "LIGHT";
    totalItems: number;
}

const phoneScreenSizeLimit = 475
const tabletScreenSizeLimit = 960
const dragEventXThreshold = 20
const paginationArrowsHidden = ref<boolean>(false)
const {pageSize = 4, showSlideSelector = true, theme = "LIGHT", totalItems = 0} = defineProps<CarouselProps>()
const totalSlides = computed(() => { return Math.ceil(totalItems / currentPageSize.value) })
const currentSlide = ref<number>(1)
const transitionName = ref<string>('slide-next')
const contentWidth = ref<number>(0)
const carouselInner = useTemplateRef<HTMLElement>('carousel-inner')
const carouselHeight = ref<string>('')
const carouselWidth = ref<string>('')
const currentPageSize = computed(() => {
  if (pageSize == 1) return 1
  const isPhoneSize = contentWidth.value<=phoneScreenSizeLimit
  const isTabletSize = tabletScreenSizeLimit>=contentWidth.value&&contentWidth.value>phoneScreenSizeLimit
  paginationArrowsHidden.value = tabletScreenSizeLimit>=contentWidth.value
  switch (true) {
    case isPhoneSize:
      return 1
    case isTabletSize:
      return 2
    default:
      return pageSize
  }
})
const dragStartX = ref<number>(0)
const dragEndX = ref<number>(0)

function changePageSizeOnResize () {
  if (currentSlide.value > totalSlides.value) {
    selectSlide(totalSlides.value);
  }

  const mainContent = document.getElementById('main')
  if (mainContent) {
    contentWidth.value = mainContent.clientWidth
    mainContent.addEventListener('resize', () => {
      contentWidth.value = mainContent.clientWidth
    })
  }
}

onMounted(() => {
  contentWidth.value = document.getElementById('main')?.clientWidth ?? 0
  window.addEventListener('resize', changePageSizeOnResize)
})

onUnmounted( () => {
  window.removeEventListener('resize', changePageSizeOnResize)
})

const itemIndexRangeForSlide = (page: number): number[] => {
  const slideOffset = currentPageSize.value * page
  const startIndex = currentPageSize.value * (page - 1)
  const endIndex = slideOffset<totalItems ? slideOffset : totalItems
  return [...Array(totalItems).keys()].slice(startIndex,endIndex)
}

const nextSlide = () => {
  transitionName.value = 'slide-next'
  if (currentSlide.value === totalSlides.value) {
      currentSlide.value = 1;
  } else {
      currentSlide.value++;
  }
}

const previousSlide = () => {
  transitionName.value = 'slide-prev'
  if (currentSlide.value === 1) {
      currentSlide.value = totalSlides.value;
  } else {
      currentSlide.value--;
  }
}

const selectSlide = (toIndex: number) => {
    if (toIndex >= 0 && toIndex <= totalSlides.value) {
      transitionName.value = toIndex>currentSlide.value ? 'slide-next' : 'slide-prev'
      currentSlide.value = toIndex
    }
}

function startDragMeasure(event: MouseEvent) {
  dragStartX.value = event.pageX;
}
function endDragMeasure(event: MouseEvent) {
  dragEndX.value = event.pageX
  processDragEvent()
}
function startTouchMeasure(event: TouchEvent) {
  dragStartX.value = event.targetTouches[0].pageX
}
function endTouchMeasure(event: TouchEvent) {
  dragEndX.value = event.changedTouches[0].pageX
  processDragEvent()
}

function processDragEvent() {
  const draggedDistance = dragStartX.value-dragEndX.value
  if (draggedDistance>0 && draggedDistance>dragEventXThreshold) {
    nextSlide()
  } else if (draggedDistance<0 && draggedDistance<dragEventXThreshold) {
    previousSlide()
  }
  dragStartX.value = 0
  dragEndX.value = 0
}

// These two Vue.js transition hooks stop a container zero height jitter
// This occurs because the content switch takes out the old items before
// the entering item has height calculated
function onBeforeEnter(_) {
  const currentSlideHeight = carouselInner.value?.clientHeight ?? 100
  const currentSlideWidth = carouselInner.value?.clientWidth ?? 100
  carouselHeight.value = `min-height: ${currentSlideHeight}px; `
  carouselWidth.value = `min-width: ${currentSlideWidth}px`
}

function onAfterEnter(_) {
  carouselHeight.value = ''
  carouselWidth.value = ''
}

defineExpose({
    nextSlide,
    previousSlide,
    selectSlide
});

</script>

<style>
.slide-next-enter-active,
.slide-prev-enter-active,
.slide-next-leave-active,
.slide-prev-leave-active {
  transition: all 0.5s ease-in-out;
}

.slide-next-enter-active,
.slide-prev-enter-active {
  transition-delay: 200ms;
}

.slide-next-leave-to,
.slide-prev-enter-from {
  opacity: 0;
  transform: translate(-80%);
}

.slide-prev-leave-to,
.slide-next-enter-from {
  opacity: 0;
  transform: translateX(80%);
}

.slide-next-enter-to,
.slide-prev-enter-to {
  position: absolute;
}

</style>
