<template>
    <div class="p-8" :class="containerStyle">
        <h2 class="font-medium text-3xl md:text-5xl my-16 md:my-20" v-if="props.title">{{ props.title }}</h2>

        <div class="overflow-x-scroll *:mb-14">
            <div class="flex items-end gap-8 py-3">
                <div class="flex-1 min-w-36" v-for="product in props.products"
                    :set="productData = getProductBySku(product.sku)">
                    <template v-if="productData">
                        <h3 class="font-bold mb-1">{{ productData.name }}</h3>

                        <div class="bg-gray-400 bg-opacity-25 p-6">
                            <NuxtImg :alt="`Image of ${productData.name}`" :src="productData.primaryImageUrl" />
                        </div>
                    </template>
                </div>
            </div>

            <!-- Statements -->
            <div class="w-fit min-w-full" v-for="(row, rowIdx) in props.rows">
                <h4 class="border-b border-b-black font-bold text-2xl pb-3 mb-3">
                    {{ row.rowTitle }}
                </h4>

                <div class="flex gap-8">
                    <div class="flex-1 min-w-36" v-for="product in props.products">
                        <template v-if="product.productRows[rowIdx]">
                            <div class="text-base md:text-lg" v-html="product.productRows[rowIdx].value"></div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import {NuxtImg} from '#components'

interface ProductComparisonProductRow {
    value: string;
}

interface ProductComparisonProduct {
    sku: string;
    productRows: ProductComparisonProductRow[];
}

interface ProductComparisonRow {
    rowTitle: string;
}

interface ProductComparisonProps {
    theme: 'DARK' | 'LIGHT';
    title: string;
    products: ProductComparisonProduct[];
    rows: ProductComparisonRow[];
}

let productData: Product | undefined;
const props = defineProps<ProductComparisonProps>();

// fetch products
const productSkus: string[] = props.products.map(product => product.sku);
const { data: products } = await siteService().fetchProductsBySku(productSkus);

const getProductBySku = (sku: string) => {
    return products.value?.find(p => p.sku === sku);
}

// styling
const containerStyle = computed(() => {
    return {
        'bg-[#ffffff]': props.theme === 'LIGHT',
        'bg-[#191919] text-white': props.theme === 'DARK'
    };
});

</script>